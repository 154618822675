// extracted by mini-css-extract-plugin
export var base = "CategoryPage-module--base--2UV_K";
export var rightAlignedContainer = "CategoryPage-module--rightAlignedContainer--AJr5C";
export var rightAlignedContainerSmall = "CategoryPage-module--rightAlignedContainerSmall--10-Fg";
export var leftAlignedContainer = "CategoryPage-module--leftAlignedContainer--xR1Lb";
export var blogContainer = "CategoryPage-module--blogContainer--aH4l7 CategoryPage-module--base--2UV_K";
export var container = "CategoryPage-module--container--3pqXW CategoryPage-module--base--2UV_K";
export var largeContainer = "CategoryPage-module--largeContainer--MhHXQ CategoryPage-module--base--2UV_K";
export var mobileSmallPadding = "CategoryPage-module--mobileSmallPadding--34RM2";
export var fullWidthMobile = "CategoryPage-module--fullWidthMobile--20BDi";
export var gridWithSidebar = "CategoryPage-module--gridWithSidebar--12_DE";
export var noMaxWidth = "CategoryPage-module--noMaxWidth--SSsSe";
export var wrap = "CategoryPage-module--wrap--1OcBM CategoryPage-module--blogContainer--aH4l7 CategoryPage-module--base--2UV_K CategoryPage-module--gridWithSidebar--12_DE";
export var header = "CategoryPage-module--header--ZEIQq";
export var articlesList = "CategoryPage-module--articlesList--31WVj";
export var sidebar = "CategoryPage-module--sidebar--2rZRh";
import {
  ArticlePreview,
  SectionTitle
} from '@animoto/components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import MediaQuery from 'react-responsive';

import AnimotoBlogLayout from '../components/AnimotoBlogLayout';
import BlogPageNavigation from '../components/BlogPageNavigation';
import RecentPostsSidebar from '../components/RecentPostsSidebar';
import { generatePaginatedUrls } from '../utils/paginationUtils';
import {
  articlesList,
  header,
  sidebar,
  wrap
} from './CategoryPage.module.css';

export default function CategoryPage({ data, pageContext }) {
  const {
    allContentfulBlogPost : posts
  } = data;
  const {
    noIndex,
    pageTitle,
    pathPrefix
  } = pageContext;

  const {
    nextUrl,
    previousUrl,
    currentPage,
    hasNextPage,
    hasPreviousPage
  } = generatePaginatedUrls({ pathPrefix, ...posts.pageInfo });

  const articles = posts.edges.map((post) => {
    const node = post.node ? post.node : post;
    const {
      featuredImage,
      fields,
      id,
      mainCategory,
      publishedOn,
      title,
      updatedAt
    } = node;

    return (
      <ArticlePreview
        key={id}
        category={(mainCategory && mainCategory.name) || ''}
        categoryUrl={(mainCategory && mainCategory.fields && `/blog/category/${mainCategory.fields.categorySlug}`) || ''}
        imageSrcFluid={featuredImage && featuredImage.fluid}
        isAlignedLeft
        postUrl={`/blog/${fields.postSlug}`}
        publishDate={publishedOn || updatedAt}
        title={title}
      />
    );
  });

  const SEOInfo = {
    canonicalSlug : '/blog',
    noIndex,
    title         : pageTitle
  };

  return (
    <AnimotoBlogLayout SEOInfo={SEOInfo}>
      <div className={wrap}>
        <div className={header}>
          <SectionTitle>{pageTitle}</SectionTitle>
        </div>
        <div className={articlesList}>
          {articles}
          {
            (hasNextPage || hasPreviousPage)
            && <BlogPageNavigation currentPage={currentPage} nextUrl={nextUrl} previousUrl={previousUrl} />
          }
        </div>

        <MediaQuery minWidth={768}>
          <RecentPostsSidebar className={sidebar} />
        </MediaQuery>
      </div>
    </AnimotoBlogLayout>
  );
}

export const pageQuery = graphql`
  query CategoryPageInformation($id: String!, $limit: Int!, $skip: Int!) {
    allContentfulBlogPost(
      limit: $limit,
      skip: $skip,
      filter: {
        fields        : { allCategories : { elemMatch : { id : { eq : $id } } } },
        noIndex       : { eq : false },
        noIndexOnBlog : { ne : true }
      },
      sort: {fields : publishedOn, order :DESC}
    ) {
      pageInfo {
        currentPage
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          title
          publishedOn
          updatedAt
          featuredImage {
            fluid(maxWidth: 670, maxHeight: 447) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          mainCategory {
            name
            fields {
              categorySlug
            }
          }
          fields {
            postSlug
          }
        }
      }
    }
  }
`;

CategoryPage.propTypes = {
  data : PropTypes.shape({
    allContentfulBlogPost : PropTypes.shape({
      edges : PropTypes.arrayOf(PropTypes.shape({
        node : PropTypes.shape({
          featuredImage : PropTypes.shape({
            fixed : PropTypes.shape({
              src : PropTypes.string
            })
          }),
          id           : PropTypes.string,
          mainCategory : PropTypes.shape({
            fields : PropTypes.shape({
              categorySlug : PropTypes.string
            }),
            name : PropTypes.string
          }),
          publishedOn         : PropTypes.string,
          retinaFeaturedImage : PropTypes.shape({
            fixed : PropTypes.shape({
              src : PropTypes.string
            })
          }),
          title     : PropTypes.string,
          updatedAt : PropTypes.string
        })
      }))
    })
  }).isRequired,
  pageContext : PropTypes.shape({
    index      : PropTypes.number,
    noIndex    : PropTypes.bool,
    pageTitle  : PropTypes.string,
    pathPrefix : PropTypes.string
  }).isRequired
};
